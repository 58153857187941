import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

class Gallery extends Component {
    render() {
        return (
            <Carousel>
                <div>
                    <img alt="img" src="images/1.png" />
                    <p className="legend">נתחיל בזה שאת מושלמת</p>
                </div>
                <div>
                    <img alt="img" src="images/3.png" />
                    <p className="legend">בעיקר לפני שמבטיחים לך אוכל</p>
                </div>
                <div>
                    <img alt="img" src="images/4.png" />
                    <p className="legend">...לא משנה בעצם</p>
                </div>
                <div>
                    <img alt="img" src="images/2.png" />
                    <p className="legend">מאחל לנו הרבה שנים של אהבה</p>
                </div>
                <div>
                    <img alt="img" src="images/5.png" />
                    <p className="legend">של הרבה טימטום</p>
                </div>
                <div>
                    <img alt="img" src="images/6.png" />
                    <p className="legend">שנצבור המון חוויות</p>
                </div>
                <div>
                    <img alt="img" src="images/9.png" />
                    <p className="legend">שתמיד נחייך ונצחק</p>
                </div>
                <div>
                    <img alt="img" src="images/10.png" />
                    <p className="legend">..רק לא בכריתים</p>
                </div>
                <div>
                    <img alt="img" src="images/7.png" />
                    <p className="legend">!אוהב אותך הכי הרבה בעולם</p>
                </div>
                <div>
                    <img alt="img" src="images/8.png" />
                    <p className="legend">.</p>
                </div>
            </Carousel>
        );
    }
};

export default Gallery;