import React from "react";
import { render } from "react-dom";
import Carousel from "./Gallery";
import './index.css'
import "react-responsive-carousel/lib/styles/carousel.min.css";

const App = () => (
    <>
    <div className="title">
        <h1>😍 מזל טוב אדרי</h1>
    </div>
    <div className="main">
        <Carousel centerMode={true}/>
    </div>
    </>
);

render(<App />, document.getElementById("root"));
